import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Card, Button, Form, Modal } from 'react-bootstrap';
import PDFViewer from './PDFViewer';
import axiosInstance from './axiosConfig';
import CustomNavbar from './Navbar';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
  }
  
  .modal-content label {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 10px;
    color: orangered;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-weight: bold;
    margin-top: 10px;
}
  @media (max-width: 767px) {
    .modal-header {
      width: auto;
    }
    .modal-body {
      position: relative;
      width: auto;
    }
    .modal-footer {
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      justify-content: center;
      padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
      background-color: var(--bs-modal-footer-bg);
      border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
      border-bottom-right-radius: var(--bs-modal-inner-border-radius);
      border-bottom-left-radius: var(--bs-modal-inner-border-radius);
      width: auto;
    }
    .fade.modal.show {
      margin-left: 10px;
    }
    .modal-content {
      width: 100%;
      padding: 20px;
      margin-left: -5px;
    }
    .modal-content label {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-top: 10px;
      color: orangered;
      font-size: medium;
    }
      
      
  }
`;

const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
  
@media (min-width: 310px) and (max-width: 359px) {
  
    width: 330px;
    margin-left: -10px;
  }


@media (min-width: 360px) and (max-width: 375px) {
  
    width: 370px;
    margin-left: -10px;
  }

  @media (min-width: 376px) and (max-width: 391px) {
  
    width: 400px;
    margin-left: -10px;
  }

   @media (min-width: 392px) and (max-width: 415px) {
  
    width: 420px;
    margin-left: -10px;
  }
    
   @media (min-width: 416px) and (max-width: 430px) {
  
    width: 440px;
    margin-left: -10px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  gap: 20px;
  margin-top: 10px;


  
`;

const FormCard = styled(Card)`
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  padding: 20px;
  text-align: center;

  .form-control {
    margin-bottom: 15px;
  }
    
`;



const PdfCard = styled(Card)`
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 100%;
  div {
    background-color:rgba(255, 255, 255, 0.15);
    
}
  

    @media (min-width: 310px) and (max-width: 359px) {
  
    div {
        width: 250px;
        margin-top: -8px;
        MARGIN-LEFT: -12.2PX;;
    }
  }

    @media (min-width: 360px) and (max-width: 375px) {
  
    div {
        width: 290px;
        margin-top: -8px;
        MARGIN-LEFT: -12.2PX;;
    }
  }

  @media (min-width: 376px) and (max-width: 391px) {
    div {
          width: 320px;
          margin-top: -8px;
          MARGIN-LEFT: -12.2PX;;
      }
  }

   @media (min-width: 392px) and (max-width: 415px) {
    div {
          width: 340px;
          margin-top: -8px;
          MARGIN-LEFT: -12.2PX;;
      }
  }
    
   @media (min-width: 416px) and (max-width: 430px) {
    div {
          width: 360px;
          margin-top: -8px;
          MARGIN-LEFT: -12.2PX;;
      }
  }



`
;
const TableCard = styled(Card)`
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  padding: 20px;


`;

const CreditsInfo = styled.p`
  color: #007bff;
`;

const PdfDownloadLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: small;
  min-width: 600px; /* Garante que a tabela tenha uma largura mínima para rolagem */

  th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    white-space: nowrap; /* Impede quebra de linha dentro das células */
  }

  th {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;
const StyledTableContainer = styled.div`
  width: 100%;
  overflow-x: auto; /* Permite rolagem horizontal em telas pequenas */
  margin-top: 20px;
  

  @media (max-width: 768px) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; /* Suaviza a rolagem em dispositivos móveis */
    
    
  }
        
`;

const RequestLogsTable = ({ logs }) => (
  <StyledTableContainer>
  <StyledTable>
    <thead>
      <tr>
        <th>Saldo Anterior</th>
        <th>Documento</th>
        <th>Preço</th>
        <th>Saldo Atual</th>
      </tr>
    </thead>
    <tbody>
      {logs
        .filter(log => log.chargedAmount !== 0)
        .slice(-8)
        .reverse()
        .map((log, index) => (
          <tr key={index}>
            <td>{log.previousCredits.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
            <td>{log.fileName}</td>
            <td>{log.chargedAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
            <td>{log.currentCredits.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
          </tr>
        ))}
    </tbody>
  </StyledTable>
  </StyledTableContainer>
);

const ConsultaEstadual = () => {
  const [placa, setPlaca] = useState('');
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [credits, setCredits] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfName, setPdfName] = useState('BinEstadual.pdf');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [requestLogs, setRequestLogs] = useState([]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (storedToken) setToken(storedToken);
    if (storedUsername) setUsername(storedUsername);
  }, []);

  useEffect(() => {
      if (token) localStorage.setItem('token', token);
      if (username) localStorage.setItem('username', username);
    }, [token, username]);

  useEffect(() => {
    if (token) {
      fetchCredits(token);  // Busca os créditos ao carregar a página
      fetchRequestLogs(token);  // Busca o histórico de consultas
    }
  }, [token]);

  const fetchCredits = async (token) => {
    try {
      const response = await axiosInstance.get('/api/user/credits', { params: { token } });
      setCredits(response.data.credits);  // Atualiza o valor de créditos na página
    } catch (error) {
      console.error('Erro ao obter créditos:', error);
      setError('Erro ao obter créditos do usuário.');
      setShowErrorModal(true);
    }
  };

  const fetchRequestLogs = async (token) => {
    try {
      const response = await axiosInstance.post('/CRV/FilteredLogs', { token });
      setRequestLogs(response.data);
    } catch (error) {
      console.error('Erro ao buscar registros:', error);
    }
  };

  const handlePlacaChange = (e) => {
    setPlaca(e.target.value.toUpperCase());
  };

  const handleConsulta = async () => {
    if (!placa || !username || !token) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      setShowErrorModal(true);
      return;
    }

    setError('');
    setPdfUrl('');
    setLoading(true);

    try {
      const response = await axiosInstance.post('/CRV/BinEstadual', null, {
        params: { placa, username, token },
        responseType: 'blob',
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);

      // Subtrai o valor da consulta dos créditos do usuário
      setCredits((prevCredits) => prevCredits - 15); // Supondo que o valor da consulta seja R$10

      // Atualiza o histórico recente após consulta bem-sucedida
      fetchRequestLogs(token);

    } catch (error) {
      console.error('Erro ao realizar a consulta:', error);
      setError('Erro ao realizar a consulta. Verifique os dados e tente novamente.');
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainContainer>
      <GlobalStyle />
      <CustomNavbar />
      <ContentContainer>
        <FormCard>
          <h1>Consulta Bin Estadual R$15</h1>
          <p>Retornando: Renavam, Chassi, Motor, UF, Documento Proprietário, Nome do Proprietário, Débitos e Restrições. </p>
          <Form.Control
            type="text"
            placeholder="Placa"
            value={placa}
            onChange={handlePlacaChange}
          />
          <Form.Control
            type="text"
            placeholder="Nome de usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Form.Control
            type="text"
            placeholder="Token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <CreditsInfo>Créditos disponíveis: R${credits.toFixed(2)}</CreditsInfo>
          <Button variant="primary" onClick={handleConsulta} disabled={loading}>
            {loading ? 'Consultando...' : 'Consultar'}
          </Button>
          
        </FormCard>
        
        <PdfCard>
        
          {pdfUrl ? (
            <>
              <PDFViewer pdfUrl={pdfUrl} />
              
            </>
            
          ) : (
            <p>{loading ? 'Carregando PDF...' : 'Nenhum PDF disponível'}</p>
          )}
          <Button variant="primary" href={pdfUrl} download={pdfName}>
            {loading ? 'Consultando...' : 'Download do PDF'}
          </Button>
        </PdfCard>
        

        <TableCard>
          <h3>Histórico Recente</h3>
          <RequestLogsTable logs={requestLogs} />
        </TableCard>
      </ContentContainer>

      {showErrorModal && (
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Erro</Modal.Title>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </MainContainer>
  );
};

export default ConsultaEstadual;
