// src/components/Precos.jsx
import React, { useEffect, useState } from 'react';
import { Table, Container } from 'react-bootstrap';
import './styles/Precos.css'; // Importa o CSS personalizado
import CustomNavbar from './Navbar'; // Importa o CustomNavbar
import LeilaoP from './LeilaoP';

function Precos() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetch('https://crlvnahora.com/api/Admin/ServicesWithoutCost')
      .then((response) => response.json())
      .then((data) => setServices(data.sort((a, b) => a.name.localeCompare(b.name)))) // Ordena os serviços
      .catch((error) => console.error('Erro ao buscar serviços:', error));
  }, []);

  const serviceDescriptions = {
    "2viaATPVe": 'Emissão da segunda via ATPV-e',
    BinNacional: 'Consulta veicular nacional que trás informações detalhadas do veículo, incluindo Renavam, Chassi, Motor, Marca, Modelo e Ano de fabricação.',
    BinEstadualA: 'Renavam, Chassi, Motor, UF, Documento Proprietário, Nome do Proprietário, Débitos e Restrições.',
    Gravame: 'Consulta se o veículo está alienado como garantia de financiamento.',
    CrvDigital: 'Documento que contém o Número de Segurança do CRV. Utilize-o para adicionar a versão digital do CRLV na CDT ou no site do Detran, acessar serviços e registrar intenção de venda.',
    "ConsultaVP":'Consulta Puxando Renavam, Chassi, Motor, Proprietário ' ,
    LeilaoP: 'Passagem por Leilões (presenciais, on-line, judiciais, seguradoras, bancos e financeiras, empresas). Classificação de avaria e securitário. Sinistro pequena, média e grande monta',
    LeilaoS: 'Passagem por Leilão',
    Renajud: 'Consulta na base Renajud',
    "Sinistro": 'Consulta de sinistro veicular: Status de danos (grande/média/pequena monta), venda e comercialização.',
    "Renainf":'Consulta RENAINF: Verifique infrações nacionais',
    // Adicione outras descrições conforme necessário 
  };

  const maintenanceServices = [
    'BinEstadual',  'NrCRV',  'string','CrlvSE', 'CrlvSC', 'CrlvRS', 'CrlvRO','CrlvPI','CrlvPE', 'CrlvPB', 'CrlvMS','CrlvES', 'CrlvDF', 'CrlvCE', 'CrlvBA', 'CrlvAM','CrlvAL'
  ];

  const crlvServices = [
    'CrlvTO', 'CrlvSP', 'CrlvRR',  'CrlvRJ', 'CrlvRN', 'CrlvPR','CrlvPA', 
    'CrlvMT', 'CrlvMG', 'CrlvMA', 'CrlvGO', 
    'CrlvAP',  'CrlvAC'

    //'CrlvSE', 'CrlvSC', 'CrlvRS', 'CrlvRO','CrlvPI','CrlvPE', 'CrlvPB', 'CrlvMS','CrlvES', 'CrlvDF', 'CrlvCE', 'CrlvBA', 'CrlvAM',CrlvAL
  ];

  const filterServices = (service) => {
    return !maintenanceServices.includes(service.name);
  };

  const getServiceIcon = (serviceName) => {
    if (serviceName.startsWith('Bin') || serviceName === 'Gravame' || serviceName === 'ConsultaVP'
    || serviceName === 'Renainf' || serviceName === 'Sinistro' || serviceName === 'Renajud' || serviceName === 'LeilaoS' || serviceName === 'LeilaoP') {
      return 'fas fa-search'; // Ícone de lupa
    }
    return 'fas fa-file-alt'; // Ícone de documento
  };

  const getServiceDescription = (service) => {
    if (crlvServices.includes(service.name)) {
      if (service.price === 20) {
        return 'Emissão imediata do CRLV Digital através do sistema.';
      }
      if (service.price === 50) {
        return 'Solicitação via WhatsApp de segunda a sexta, das 8h às 18h. PDF enviado em poucas horas. Emissão feita diretamente no Detran ou com despachantes parceiros.';
      }
    }
    return serviceDescriptions[service.name] || 'Descrição não disponível.';
  };

  const getServiceEmission = (service) => {
    if (service.price === 20 || service.price === 10 || service.price === 15 || service.price === 25 || service.price === 30 || service.price === 40) {
      return 'Sistema';
    }
    if (service.price === 50) {
      return 'WhatsApp';
    }
    return '';
  };

  const formatServiceName = (name) => {
    switch (name) {
      case 'BinEstadualA':
        return 'Base Estadual';
      case 'BinNacional':
        return 'Base Nacional';
      case 'CrvDigital':
        return 'CRV Digital';
      case '2viaATPVe':
        return '2 Via ATPVE';
      case 'ConsultaVP':
        return 'Consulta Premium';
        case 'Sinistro':
        return 'Sinistro';
      case 'LeilaoS':
        return 'Leilão Simples';
      case 'LeilaoP':
        return 'Leilão Premium';

      default:
        if (name.startsWith('Crlv')) {
          return name.replace('Crlv', 'CRLV ');
        }
        return name;
    }
  };

  const wrapText = (text, length) => {
    if (text.length > length) {
      const regex = new RegExp(`(.{1,${length}})(\\s|$)`, 'g');
      return text.match(regex).join('\n');
    }
    return text;
  };

  return (
    <>
      <CustomNavbar /> {/* Adiciona o CustomNavbar */}
    <Container>
      <h1 className="my-4 text-center page-title">Preços dos Serviços</h1>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Serviço</th>
            <th>Preço</th>
            <th>Descrição</th>
            <th>Emissão</th>
          </tr>
        </thead>
        <tbody>
          {services.filter(filterServices).map((service) => (
            <tr key={service.id}>
              <td>
                <i className={`${getServiceIcon(service.name)} table-icon`}></i> {formatServiceName(service.name)}
              </td>
              <td>R$ {service.price.toFixed(2)}</td>
              <td className="description">{wrapText(getServiceDescription(service), 80)}</td>
              <td>{getServiceEmission(service)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
    </>
  );
}

export default Precos;
