import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Card, Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import styled, { createGlobalStyle } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { trackAddCredits } from './facebookPixel'; // Importa o evento de adição de créditos
//import './Pagamentos.css';
import CustomNavbar from './Navbar'; // Importando o CustomNavbar

// Estilos globais para a aplicação
const GlobalStyle = createGlobalStyle`

    /* Centralizar o QR Code */
.qr-code {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}
    
    /* Aumentar a largura do modal */
.modal .modal-dialog {
    max-width: 800px;
}
/* Estilos para títulos e ícones */
.payment-option h3, .how-to-pay h3, .purchase-details h3 {
    display: flex;
    align-items: center;
    width: max-content;
}
    .payment-option h3 img, .how-to-pay ol li img, .purchase-details h3 img {
    margin-right: 8px;
}
    p {
    margin-bottom: 20px;
    font-size: 1.3em;
    padding-top: 0px;
}
    .btn-close {
    margin-right: 240px;
}
    
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: -30px;
}

input.form-control {
    width: 100%;
    margin-left: 5px;
}

/* Espaçamento entre as informações de cada coluna */
.modal-body .col-md-6 {
    margin-bottom: 20px;
}

/* Ícone de copiar dentro da caixa de texto */
.copy-textbox {
    position: relative;
}

.copy-textbox input {
    padding-right: 30px; /* Espaço para o ícone */
}

.copy-icon {
    
        position: absolute;
        
}

.copy-textbox input {
    padding-right: 30px;
    margin-left: -20px;
}


  div#root {
    background-color: #fff;
    width: auto;
   
 }
    div {
    background: #fff;
    width: auto;
}
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
    width: auto;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    min-height: 100vh;
    padding: 20px;
    
  }
    .card {
    width: 450px;
}

.text-center {
    text-align: left;
}
  .page-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }

  .form-control {
    width: auto;
    border: 1px solid #007bff;
}
    input#formAmount {
    border-color: #007bff;
    background-image: none;
    
}

    input#formEmail {
    border-color: #007bff;
    background-image: none;
    
}
    input#formCpf {
    border-color: #007bff;
    background-image: none;
    
}
    input#formToken {
    border-color: #007bff;
    background-image: none;
    
}


@media (max-width: 768px) {

.modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: var(--bs-modal-padding);
        width: auto;
        /* overflow-x: auto; */
    }

    .copy-textbox input {
    padding-right: 30px;
    margin-left: -10px;
}

   /* Aumentar a largura do modal */
.modal .modal-dialog {
}



    .card {
    width: AUTO;
    margin-top: 120px;
}




     
    div {
    background: #fff;
    width: auto;
    padding: 0.5px;
}
    .page-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }
    .modal-header .btn-close {
      margin-right: 0px;
    }

    .modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
    font-size: large;
}

    .page-title.mb-4.card-title.h5 {
        /* margin-left: -40px; */
        /* font-size: larger; */
        width: -moz-fit-content;
        width: auto;
    }
.copy-icon {
    position: relative;
}
      .modal-footer {
    justify-content: center;
}
        .modal-content {
        padding: 20px;
        max-width: 100%;
    }
    .text-success {
      --bs-text-opacity: 1;
      color: rgb(251 244 246) !important;
      background: #0090ff;
    }
        
         .form-control {
        width: auto;
        
        }
        

        .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-top: -50px;
    padding: 20px;
    width: 100%;
     
  }
    .mb-3 {
    margin-bottom: 1rem !important;
    font-weight: bold;
    }
  }

  @media (min-width: 310px) and (max-width: 359px) {
        
        .haWFNx .navbar {
            flex-direction: row;
            padding-top: 10px;
            width: 95%;
            background-color: #fff;
        }

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            margin-top: -40px;
            padding: 20px;
            width: 100%;
            
        }  

        .modal-content {
        /* padding: 20px; */
        width: 80%;
        }
  }

   @media (min-width: 360px) and (max-width: 375px) {
        
        .haWFNx .navbar {
            flex-direction: row;
            padding-top: 10px;
            width: 95%;
            background-color: #fff;
        }
            
        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            margin-top: -70px;
            padding: 20px;
            width: 100%;
        }  
        
        .modal-content {
        /* padding: 20px; */
        width: 85%;
        }
     }

  @media (min-width: 376px) and (max-width: 391px) {
        
        .haWFNx .navbar {
            flex-direction: row;
            padding-top: 10px;
            width: 95%;
            background-color: #fff;
        }

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            margin-top: -70px;
            padding: 20px;
            width: 100%;
            
        }
    }

  @media (min-width: 392px) and (max-width: 415px) {
       
        .haWFNx .navbar {
            flex-direction: row;
            padding-top: 10px;
            width: 95%;
            background-color: #fff;
        }

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            margin-top: -70px;
            padding: 20px;
            width: 100%;
            
        }

        .modal-content {
        /* padding: 20px; */
        width: 98%;
        }

    }

  @media (min-width: 416px) and (max-width: 430px) {

    .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-top: -70px;
    padding: 20px;
    width: 100%;
     
  }
    .haWFNx .navbar {
        flex-direction: row;
        padding-top: 10px;
        width: 95%;
        background-color: #fff;
    }

    .modal-content {
        /* padding: 20px; */
        width: 100%;
        }
        
   }

   
`;



// Cabeçalho que contém a barra de navegação 
const Header = styled.header`
  width: 100%;
  height: auto;
  margin-left: 0px;
  background-color: #fff;

  @media (min-width: 310px) and (max-width: 359px) {
  width: 100%;
  margin-left: -45px;
  
  
  }

   @media (min-width: 360px) and (max-width: 375px) {
          width: 100%;
           margin-left: -25px;
           height: auto; /* Mantém a proporção original */
            
           
     }

  @media (min-width: 376px) and (max-width: 391px) {
            width: 100%;
           margin-left: -15px;
    }

   @media (min-width: 392px) and (max-width: 415px) {
           width: 100%;
           margin-left: 0px;
    }
    
   @media (min-width: 416px) and (max-width: 430px) {

            width: 100%;
            margin-left: -5px;
           
        
   }
`;






function Pagamentos() {
    const [amount, setAmount] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [token, setToken] = useState('');
    const [paymentLink, setPaymentLink] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [paymentId, setPaymentId] = useState(''); 
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [paymentConfirmed, setPaymentConfirmed] = useState(false);
    const [navbarExpanded, setNavbarExpanded] = useState(false);
    const navigate = useNavigate();
    const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);

    const trackAddCredits = (amount) => {
        // Disparar o evento personalizado para o GTM
        window.dataLayer.push({
            'event': 'addCredits',
            'Value': amount // Valor do Pix
        });
    };

    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        const storedCpfCnpj = localStorage.getItem('cpfCnpj');
        const storedToken = localStorage.getItem('token');

        if (storedEmail) setEmail(storedEmail);
        if (storedCpfCnpj) setCpf(storedCpfCnpj);
        if (storedToken) setToken(storedToken);
    }, []);

    useEffect(() => {
        let intervalId;

        if (paymentId) {
            const startChecking = setTimeout(() => {
                intervalId = setInterval(async () => {
                    try {
                        const response = await fetch(`https://crlvnahora.com/api/Payment/get-payment-status/${paymentId}`);
                        if (!response.ok) {
                            throw new Error(`Error ${response.status}: ${await response.text()}`);
                        }

                        const data = await response.json();
                        if (data.status === 'pago') {
                            setPaymentConfirmed(true);
                            setModalIsOpen(false); 
                            clearInterval(intervalId);
                            setPaymentId(''); 
                            trackAddCredits(amount); // Dispara o evento de adição de créditos com o valor do Pix
                        }
                    } catch (error) {
                        console.error("Erro ao verificar status do pagamento:", error);
                    }
                }, 3000);
            }, 5000);

            return () => {
                clearTimeout(startChecking);
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }
    }, [paymentId]);

    const handleCreatePayment = async (e) => {
        e.preventDefault();
        const formattedAmount = parseFloat(amount.replace(/\./g, '').replace(',', '.'));

        if (formattedAmount < 10.00 && formattedAmount !== 0.03) {
            setAlertModalIsOpen(true);
            return;
        }

        try {
            const response = await fetch('https://crlvnahora.com/api/Payment/create-pix-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    UserToken: token,
                    Amount: formattedAmount,
                    Email: email,
                    Cpf: cpf
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error ${response.status}: ${errorText}`);
            }

            const data = await response.json();
            setPaymentLink(data.pixLink);
            setQrCode(data.qrCodeBase64);
            setPaymentId(data.paymentId); 
            setModalIsOpen(true); 
        } catch (error) {
            console.error("Erro ao criar pagamento PIX:", error);
            alert(`Erro ao criar pagamento PIX: ${error.message}`);
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Texto copiado para a área de transferência');
        }, (err) => {
            alert('Falha ao copiar o texto: ', err);
        });
    };

    const handleCpfChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 14) return;
        if (value.length <= 11) {
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        } else {
            value = value.replace(/^(\d{2})(\d)/, '$1.$2');
            value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
            value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
            value = value.replace(/(\d{4})(\d)/, '$1-$2');
        }
        setCpf(value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (email) => {
        return email.includes('@');
    };

    const handleAmountChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        value = (Number(value) / 100).toFixed(2) + '';
        value = value.replace('.', ',');
        value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        setAmount(value);
    };

    const handleClosePaymentConfirmed = () => {
        setPaymentConfirmed(false);
        navigate('/DocumentosDigitais');
      };

      const handleCloseAlertModal = () => {
        setAlertModalIsOpen(false);
    };

    return (
        <div>
            <GlobalStyle />
            <CustomNavbar /> {/* Usando o componente CustomNavbar */}
            <Container className="d-flex justify-content-center align-items-center min-vh-100">
                <Row>
                    <Col>
                        <Card className="p-4 shadow">
                            <Card.Body>
                                <Card.Title className="page-title mb-4">Recarga via PIX</Card.Title>
                                <Card.Text className="text-center mb-4">
                                Adicione créditos à sua conta no sistema.
                                </Card.Text>
                                <Form onSubmit={handleCreatePayment}>
                                    <Form.Group controlId="formAmount">
                                        <Form.Control type="text" value={amount} onChange={handleAmountChange} placeholder="Valor" required />
                                    </Form.Group>
                                    <Form.Group controlId="formEmail">
                                        <Form.Control type="email" value={email} onChange={handleEmailChange} placeholder="Email" required isInvalid={!validateEmail(email)} />
                                    </Form.Group>
                                    <Form.Group controlId="formCpf">
                                        <Form.Control type="text" value={cpf} onChange={handleCpfChange} placeholder="CPF ou CNPJ" required isInvalid={cpf.length !== 14 && cpf.length !== 18} />
                                    </Form.Group>
                                    <Form.Group controlId="formToken">
                                        <Form.Control type="text" value={token} onChange={e => setToken(e.target.value)} placeholder="Token do Usuário" required />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="w-100">Gerar QRcode PIX</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalhes do Pagamento PIX</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6}>
                                <h3>Como pagar?</h3>
                                <hr />
                                <ol className="how-to-pay">
                                        <p>
                                            <img src="nr1.png" alt="1" width="24" /> Entre no app ou site do seu banco e escolha a opção de pagamento via Pix.
                                        </p>
                                        <p>
                                            <img src="nr2.png" alt="2" width="24" /> Escaneie o código QR ou copie e cole o código de pagamento.
                                        </p>
                                        <p>
                                            <img src="nr3.png" alt="3" width="24" /> Pronto! O pagamento será creditado na hora.
                                        </p>
                                </ol>
                                <hr />
                                <p>O Pix tem um limite diário de transferências. Para mais informações, por favor, consulte seu banco.</p>
                                <hr />
                                <div className="purchase-details">
                                    <h3><img src="detalhe.png" alt="Detalhes" width="24" /> Detalhes da compra</h3>
                                    <hr />
                                    <p>Compra de Créditos: R$ {amount}</p>
                                    <hr />
                                    <p>Total: R$ {amount}</p>
                                </div>
                            </Col>
                            <Col md={6} className="text-center">
                                <div className="payment-option">
                                    <h3>Código QR</h3>
                                    <hr />
                                    {qrCode ? (
                                        <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" className="qr-code" />
                                    ) : (
                                        <p>QR Code não disponível</p>
                                    )}
                                </div>
                                <hr />
                                <div className="payment-option mt-4 copy-textbox">
                                    <h3>Chave copia e cola</h3>
                                    <hr />
                                    {paymentLink ? (
                                        <>
                                            <Form.Control type="text" readOnly value={paymentLink} />
                                            <img src="copiaCola.png" alt="Copiar" className="copy-icon" onClick={() => handleCopyToClipboard(paymentLink)} />
                                        </>
                                    ) : (
                                        <p>Link não disponível</p>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalIsOpen(false)}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={paymentConfirmed} onHide={() => setPaymentConfirmed(false)} centered>
                    <Modal.Body className="text-center" style={{ backgroundColor: '#d4edda', color: '#155724' }}>
                        <img src="aprovado1.png" alt="Aprovado" style={{ width: '100px', marginBottom: '20px' }} />
                        <h4 className="mb-3">Pagamento Confirmado</h4>
                        <p>Pagamento realizado com sucesso. Os créditos foram adicionados à sua conta e você está apto a utilizar as consultas do sistema.</p>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: '#d4edda' }}>
                        <Button variant="success" onClick={() => setPaymentConfirmed(handleClosePaymentConfirmed)}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={alertModalIsOpen} onHide={handleCloseAlertModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Alerta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <p>O valor mínimo para recarga é R$ 10,00.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseAlertModal}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
}

export default Pagamentos;
