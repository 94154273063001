import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CustomNavbar from './Navbar';

const services = [
  { name: 'Cadastro', path: '/cadastro', description: 'Criar conta no sistema', image: '/Cadastro.png' },
  { name: 'Inserir Créditos', path: '/pagamentos', description: 'Fazer recarga pix', image: '/InserirCredito.png' },
  { name: 'Emissão de Documentos Digitais', path: '/DocumentosDigitais', description: '2° via ATPV-E, CRLV-E, CRV-E.', image: '/14.png' },
  { name: 'Consulta Base Estadual', path: '/consultaestadual', description: 'Informações do veículo.', image: '/16.png' },
  { name: 'Consulta Base Nacional', path: '/consultabinnacional', description: 'Informações do veículo.', image: '/10.png' },
  { name: 'Consulta Veicular Premium', path: '/consultapremium', description: 'Informações do veículo.', image: '/9.png' },
  { name: 'Consulta Gravame (SNG)', path: '/consultagravame', description: 'Sistema Nacional de Gravames', image: '/19.png' },
  { name: 'Consulta Leilão Premium', path: '/leilaop', description: 'Leilão completo + Score', image: '/18.png' },
  { name: 'Consulta Leilão Simples', path: '/leilaos', description: 'Leilão simples', image: '/17.png' },
  { name: 'Consulta Renainf', path: '/renainf', description: 'Consulta de multas', image: '/ConsultaRenainf.png' },
  { name: 'Consulta Sinistro', path: '/sinistro', description: 'Consulta danos e acidentes', image: '/ConsultaSinistro.png' },
  { name: 'Rembolso', path: '/reembolso', description: 'Solicitar rembolso', image: '/reembolso.png' },
  { name: 'Tutorial', path: '/tutorial', description: 'Aprenda usar o sistema', image: '/Tutorial.png' },
  
  { name: 'Suporte', Link: 'https://api.whatsapp.com/send?phone=5511999184789&text=Ol%C3%A1,%20preciso%20falar%20com%20suporte.', description: 'Falar com suporte', image: '/Suporte.png' },
  // Adicione mais serviços conforme necessário
];



const Container = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
   


  @media (min-width: 310px) and (max-width: 359px) {
    margin-top: 10px;
    width: 297px;         
  
  }
  
   @media (min-width: 360px) and (max-width: 375px) {
      width: auto; 
      
     }

  @media (min-width: 376px) and (max-width: 391px) {
    width: auto;
    }

   @media (min-width: 392px) and (max-width: 415px) {
    width: auto;
    margin-left: -20px;
    margin-right: -20px;
    }
    
   @media (min-width: 416px) and (max-width: 430px) {
   width: auto;
    margin-left: -20px;
    margin-right: -20px;
   }
  
}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 60%;
  

  @media (min-width: 310px) and (max-width: 359px) {
    margin-top: -10px;
     width: auto;
  }
  
   @media (min-width: 360px) and (max-width: 375px) {
      margin-top: -80px;
      width: auto;
      
     }

  @media (min-width: 376px) and (max-width: 391px) {
        margin-top: -80px;
        width: auto;
    }

   @media (min-width: 392px) and (max-width: 415px) {
      margin-top: -80px;
       width: auto;
    }
    
   @media (min-width: 416px) and (max-width: 430px) {
      margin-top: -80px;
      width: auto;
   }

`;

const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%; 
   margin-top: -15px;
  
    @media (min-width: 310px) and (max-width: 359px) {
    
          width: 297px;   
          margin-top: -10px;  
          margin-left: -10px;
  }
      @media (min-width: 360px) and (max-width: 375px) {
      width: 100%;   
          margin-top: -10px;  
          margin-left: -30px;
     }

  @media (min-width: 376px) and (max-width: 391px) {
          width: auto;   
          margin-top: -10px; 
          margin-left: -30px;
          
    }

   @media (min-width: 392px) and (max-width: 415px) {
          width: auto; 
          margin-top: -10px;
          margin-left: 0px;
    }
    
   @media (min-width: 416px) and (max-width: 430px) {

          width: auto; 
          margin-top: -10px;
          margin-left: -10px;
          margin-left: 0px;
   }
`;

const CardImage = styled.img`
  width: 97%;
  height: 220px;
  object-fit: cover;

 

  @media (min-width: 310px) and (max-width: 359px) {
  width: 100%;
  margin-left: -45px;
  
  
  }

   @media (min-width: 360px) and (max-width: 375px) {
          width: 100%;
           margin-left: -25px;
           height: auto; /* Mantém a proporção original */
            
           
     }

  @media (min-width: 376px) and (max-width: 391px) {
            width: 100%;
           margin-left: -15px;
    }

   @media (min-width: 392px) and (max-width: 415px) {
           width: 100%;
           margin-left: 0px;
    }
    
   @media (min-width: 416px) and (max-width: 430px) {

            width: 100%;
            margin-left: -5px;
           
        
   }
`;

const CardBody = styled.div`
  padding: 1rem;
  margin-left: 5px;
  width: auto;

  @media (min-width: 310px) and (max-width: 359px) {
  padding: 1rem;
  margin-left: -10px;
  width: 100%;
  }

   @media (min-width: 360px) and (max-width: 375px) {
      padding: 1rem;
      margin-left: -10px;
        width: auto;
     }

  @media (min-width: 376px) and (max-width: 391px) {
      padding: 1rem;
      margin-left: -10px;
        width: auto;
    }

   @media (min-width: 392px) and (max-width: 415px) {
          padding: 1rem;
          margin-left: -10px;
           width: auto;
    }
    
   @media (min-width: 416px) and (max-width: 430px) {
          padding: 1rem;
          margin-left: 10px;
           width: auto;
   }
`;

const CardTitle = styled.h5`
  color: #007bff;
  margin: 0 0 0.5rem 0;
`;

const CardText = styled.p`
  color: #333;
  margin: 0 0 1rem 0;

  
`;

const Button = styled.button`
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    background-color: #0056b3;
  }
`;

const Ribbon = styled.div`
  
        --f: 10px;
    --r: 15px;
    --t: 10px;
    position: absolute;
    inset: var(--t) calc(-1* var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%, calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)), var(--r) calc(50% - var(--f) / 2));
    background: #ff9800;
    box-shadow: 0 calc(-1* var(--f)) 0 inset #0005;
    margin-right:8px;
    font-size: larger;
    font-weight: bolder;
    color: white;


 @media (max-width: 767px) {
      
           --f: 10px;
        --r: 15px;
        --t: 10px;
        position: sticky;
        inset: var(--t) calc(-1* var(--f)) auto auto;
        padding: 0 10px var(--f) calc(10px + var(--r));
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%, calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)), var(--r) calc(50% - var(--f) / 2));
        background: #ff9800;
        box-shadow: 0 calc(-1* var(--f)) 0 inset #0005;
        /* margin-right: -100px; */
        margin-top: -40px;
        font-size: larger;
        font-weight: bolder;
        width: 180px;
        margin-left: 188px;
        color: white;
    }
        
   @media (max-min: 965) and (max-width: 1200){   
    .krDMJH {
    --f: 10px;
    --r: 15px;
    --t: 10px;
    position: absolute;
    inset: var(--t) calc(-1* var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%, calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)), var(--r) calc(50% - var(--f) / 2));
    background: #ff9800;
    box-shadow: 0 calc(-1* var(--f)) 0 inset #0005;
    margin-right: 90px;
    font-size: larger;
    font-weight: bolder;
    color: white;
      }


    }


`;

const MainContainer = styled.div`
  
 
  

`;
const GreenRibbon = styled(Ribbon)`
  background: #4caf50; /* Green color */
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
`;

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    
    <MainContainer>
       <CustomNavbar />
    <Container>
            <Grid>
        {services.map((service, index) => (
          <Card key={index}>
            <div style={{ position: 'relative' }}>
              
              
              {['/10.png' ].includes(service.image) && (
                <GreenRibbon>R$15</GreenRibbon>
              )}
              {['/14.png' ].includes(service.image) && (
                <GreenRibbon></GreenRibbon>
              )}
              {['/16.png' ].includes(service.image) && (
                <GreenRibbon>R$15</GreenRibbon>
              )}
              {['/ConsultaRenainf.png' ].includes(service.image) && (
                <GreenRibbon>R$10</GreenRibbon>
              )}
              {['/ConsultaSinistro.png' ].includes(service.image) && (
                <GreenRibbon>R$10</GreenRibbon>
              )}
              {['/18.png' ].includes(service.image) && (
                <GreenRibbon>R$30</GreenRibbon>
              )}
              {['/17.png' ].includes(service.image) && (
                <GreenRibbon>R$20</GreenRibbon>
              )}
              {['/9.png' ].includes(service.image) && (
                <GreenRibbon>R$15</GreenRibbon>
              )}
              {['/19.png' ].includes(service.image) && (
                <GreenRibbon>R$10</GreenRibbon>
              )}
              <CardImage src={service.image} alt={service.name} />
            </div>
            <CardBody>
              <CardTitle>{service.name}</CardTitle>
              <CardText>{service.description}</CardText>
              <Button
          onClick={() => {
            if (service.Link) {
              window.open(service.Link, '_blank'); // Abre o WhatsApp em uma nova aba
            } else if (service.path) {
              navigate(service.path); // Navega internamente
            }
          }}
        >
          Acessar
        </Button>
            </CardBody>
          </Card>
        ))}
      </Grid>
    </Container>
    
    </MainContainer>
    
  );
};



export default Dashboard;
