import styled, { createGlobalStyle } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import PDFViewer from './PDFViewer';
import { Card, Modal, Button, Form, Navbar, Nav } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';
import CustomNavbar from './Navbar';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
  }
  
  .modal-content label {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 10px;
    color: orangered;
  }
  
  @media (max-width: 767px) {
    .modal-header {
      width: auto;
    }
    .modal-body {
      position: relative;
      width: auto;
    }
    .modal-footer {
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      justify-content: center;
      padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
      background-color: var(--bs-modal-footer-bg);
      border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
      border-bottom-right-radius: var(--bs-modal-inner-border-radius);
      border-bottom-left-radius: var(--bs-modal-inner-border-radius);
      width: auto;
    }
    .fade.modal.show {
      margin-left: 10px;
    }
    .modal-content {
      width: 100%;
      padding: 20px;
      margin-left: -5px;
    }
    .modal-content label {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-top: 10px;
      color: orangered;
      font-size: medium;
    }
  }
`;

const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media (max-width: 767px) {
    div {
      height: 100%;
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -10px;
      background: #fff;
    }
  }
`;

const Header = styled.header`
  width: 100%;
  .navbar {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 46px;
    font-size: 1.125rem;
    border-bottom: var(--bs-border-width) var(--bs-border-style) #dee2e603 !important;

    .navbar-brand {
      color: #000000;

      .bi {
        width: 40px;
        height: 32px;
      }
    }
    .nav-pills {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
    .nav-link.active {
      background-color: #fff;
      color: #007bff;
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    .navbar {
      background: #fff;
      display: contents;
      justify-content: center;
      align-items: center;
      line-height: 46px;
    }

    .navbar-brand {
      margin-right: auto;
    }
    .nav-pills {
      justify-content: flex-start;
      flex-grow: 1;
    }
    .nav-link {
      color: #667;
      border-bottom: 1px solid;
      border-bottom-color: rgba(0, 0, 0, .28);
      letter-spacing: 0;
      border-radius: inherit;
      width: 100%;
    }
  }
`;

const ContentContainer = styled.div`
  display: grid;
  padding: 20px;
  max-width: auto;
  box-shadow: none;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 0px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: auto;
    background-color: #ffffff;
    margin-left: -300px;

    @media (max-width: 767px) {
      div {
        padding: 11px;
        background: transparent;
      }
      .lShME {
        padding: 6px;
        margin: 10px;
      }
      .kpYZsG {
        grid-template-columns: 1fr;
      }
      .py-3 {
        padding-top: 2rem !important;
      }
    }
  }
`;

const FormCard = styled(Card)`
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  width: 400px;

  .form-control {
    border: var(--bs-border-width) solid #0a0a0a;
    appearance: auto;
  }

  @media (max-width: 768px) {
    padding: 15px;
    height: auto;
    background-color: #ffffff;
    margin-left: 300px;

    select.form-control {
      appearance: auto;
      padding: 10px;  
      margin-left: 3px;
    }
  }
`;

const PdfCard = styled(Card)`
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  width: 400px; 

  @media (max-width: 768px) {
    padding: 0px;
    margin-left: 300px;
  }
`;

const TableCard = styled(Card)`
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  width: fit-content;
  margin-left: 30px;

  @media (max-width: 768px) {
    padding: 0px;
    margin-left: 300px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: small;

  th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 768px) {
    th, td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 4px;
    }
  }
`;

const CreditsInfo = styled.p`
  margin-top: 4px;
  color: #516377;
`;

const SubmitButton = styled.button`
  background-color: ${(props) => (props.special ? '#28a745' : '#007bff')};
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  &:disabled {
    background-color: #6c757d;
  }

  @media (max-width: 1020px) {
    padding: 8px 16px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const LoadingImage = styled.img`
  display: block;
  margin: 20px auto;
`;

const DocumentImage = styled.img`
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 20px auto;

  @media (max-width: 1020px) {
    max-width: 100%;
  }
`;

const PdfDownloadLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const RequestLogsTable = ({ logs }) => (
  <StyledTable>
    <thead>
      <tr>
        <th>Saldo Anterior</th>
        <th>Documento</th>
        <th>Preço</th>
        <th>Saldo Atual</th>
      </tr>
    </thead>
    <tbody>
      {logs
        .filter(log => log.chargedAmount !== 0)
        .slice(-8)
        .reverse()
        .map((log, index) => (
          <tr key={index}>
            <td>{formatCurrency(log.previousCredits)}</td>
            <td>
            {log.fileName ? (
              <a
                href={`${process.env.REACT_APP_API_BASE_URL}/CRV/DownloadFile?fileName=${log.fileName}&token=${localStorage.getItem('token')}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {log.fileName}
              </a>
            ) : (
              "Arquivo não disponível"
            )}
          </td>
            <td>{formatCurrency(log.chargedAmount)}</td>
            <td>{formatCurrency(log.currentCredits)}</td>
          </tr>
        ))}
    </tbody>
  </StyledTable>
);

const formatCurrency = (amount) => {
  return amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

const DocumentosDigitais = () => {
  const [service, setService] = useState('');
  const [placa, setPlaca] = useState('');
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [credits, setCredits] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfName, setPdfName] = useState('documento.pdf');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSupportButton, setShowSupportButton] = useState(false);
  const [services, setServices] = useState([]);
  const [isSpecial, setIsSpecial] = useState(false);
  const [requestLogs, setRequestLogs] = useState([]);
  const [debouncedToken, setDebouncedToken] = useState(token);

    const trackPurchase = (service, price) => {
      // Disparar o evento personalizado para o GTM
      window.dataLayer.push({
          'event': 'successfulConsultation',
          'service': service,
          'Value': price
      });
  };

  const navigate = useNavigate();

  const specialStates = [
    'CrlvAM','CrlvAL', 'CrlvBA', 'CrlvES', 'CrlvPB', 'CrlvPE', 
    'CrlvRS', 'CrlvRO', 'CrlvSC',  'CrlvSE'
  ];
  
  // Carregar valores do localStorage ao inicializar o componente
    useEffect(() => {
      const storedToken = localStorage.getItem('token');
      const storedUsername = localStorage.getItem('username');
      if (storedToken) {
        setToken(storedToken);
      }
      if (storedUsername) {
        setUsername(storedUsername);
      }
    }, []);
  
    // Salvar valores no localStorage quando mudarem
    useEffect(() => {
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
    }, [token, username]);

  const isSpecialState = (service) => {
    return specialStates.includes(service);
  };

  const validatePlaca = (placa) => {
    const oldPattern = /^[A-Z]{3}[0-9]{4}$/i;
    const newPattern = /^[A-Z]{3}[0-9][A-J][0-9]{2}$/i;
    return oldPattern.test(placa) || newPattern.test(placa);
  };

  const handlePlacaChange = (e) => {
    const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '').slice(0, 7);
    setPlaca(value);
  };

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/api/Admin/ServicesWithoutCost');
      const servicePrices = response.data.reduce((acc, service) => {
        acc[service.name] = service.price;
        return acc;
      }, {});

      const updatedServices = [
        { label: `CRLV AC - R$${servicePrices.CrlvAC} - Emissão na hora`, value: 'CrlvAC', price: servicePrices.CrlvAC },
        { label: `CRLV AP - R$${servicePrices.CrlvAP} - Emissão na hora`, value: 'CrlvAP', price: servicePrices.CrlvAP },
        { label: `CRLV CE - R$${servicePrices.CrlvCE} - Emissão na hora`, value: 'CrlvCE', price: servicePrices.CrlvCE },
        { label: `CRLV DF - R$${servicePrices.CrlvDF} - Emissão na hora`, value: 'CrlvDF', price: servicePrices.CrlvDF },
        { label: `CRLV GO - R$${servicePrices.CrlvGO} - Emissão na hora`, value: 'CrlvGO', price: servicePrices.CrlvGO },
        { label: `CRLV MA - R$${servicePrices.CrlvMA} - Emissão na hora`, value: 'CrlvMA', price: servicePrices.CrlvMA },
        { label: `CRLV MG - R$${servicePrices.CrlvMG} - Emissão na hora`, value: 'CrlvMG', price: servicePrices.CrlvMG },
        { label: `CRLV MT - R$${servicePrices.CrlvMT} - Emissão na hora`, value: 'CrlvMT', price: servicePrices.CrlvMT },
        { label: `CRLV MS - R$${servicePrices.CrlvMS} - Emissão na hora`, value: 'CrlvMS', price: servicePrices.CrlvMS },
        { label: `CRLV PA - R$${servicePrices.CrlvPA} - Emissão na hora`, value: 'CrlvPA', price: servicePrices.CrlvPA },
        { label: `CRLV PI - R$${servicePrices.CrlvPI} - Emissão na hora`, value: 'CrlvPI', price: servicePrices.CrlvPI },
        { label: `CRLV PR - R$${servicePrices.CrlvPR} - Emissão na hora`, value: 'CrlvPR', price: servicePrices.CrlvPR },
        //{ label: `CRLV RJ - R$${servicePrices.CrlvRJ} - Emissão na hora`, value: 'CrlvRJ', price: servicePrices.CrlvRJ },
        { label: `CRLV RN - R$${servicePrices.CrlvRN} - Emissão na hora`, value: 'CrlvRN', price: servicePrices.CrlvRN },
        { label: `CRLV RR - R$${servicePrices.CrlvRR} - Emissão na hora`, value: 'CrlvRR', price: servicePrices.CrlvRR },
        { label: `CRLV SP - R$${servicePrices.CrlvSP} - Emissão na hora`, value: 'CrlvSP', price: servicePrices.CrlvSP },
        { label: `CRLV TO - R$${servicePrices.CrlvTO} - Emissão na hora`, value: 'CrlvTO', price: servicePrices.CrlvTO }
      ];

      const storedServices = JSON.parse(localStorage.getItem('services') || '[]');

      if (JSON.stringify(storedServices) !== JSON.stringify(updatedServices)) {
        setServices(updatedServices);
        localStorage.setItem('services', JSON.stringify(updatedServices));
      } else {
        setServices(storedServices);
      }
    } catch (error) {
      console.error('Erro ao obter serviços:', error);
    }
  };

  useEffect(() => {
    fetchServices();

    const fetchCredits = async () => {
      if (debouncedToken) {
        try {
          const response = await axiosInstance.get('/api/user/credits', {
            params: { token: debouncedToken }
          });
          setCredits(response.data.credits);
        } catch (error) {
          console.error('Erro ao obter créditos:', error);
          setError('Erro ao obter créditos do usuário.');
          setShowErrorModal(true);
        }
      }
    };

    fetchCredits();

    const doNotShow = localStorage.getItem('doNotShowInfoModal') === 'true';
    setDoNotShowAgain(doNotShow);
  }, [debouncedToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedToken(token);
    }, 3000);

    return () => {
      clearTimeout(handler);
    };
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchRequestLogs(token);
    }
  }, [token]);

  const fetchRequestLogs = async (token) => {
    try {
      const response = await axiosInstance.post('/CRV/FilteredLogs', { token });
      setRequestLogs(response.data);
    } catch (error) {
      console.error('Erro ao buscar registros:', error);
    }
  };

  const handleRequest = async () => {
    if (!service) {
      setError('Por favor, selecione um serviço.');
      setShowErrorModal(true);
      return;
    }

    if (!validatePlaca(placa)) {
      setError('Por favor, insira uma placa válida.');
      setShowErrorModal(true);
      return;
    }

    if (isSpecial) {
      const message = `Olá, quero que emita o ${service}, placa: ${placa}.`;
      window.open(`https://api.whatsapp.com/send?phone=5511999184789&text=${encodeURIComponent(message)}`);
      setLoading(false);
      return;
    }

    const selectedService = services.find(s => s.value === service);
    if (!selectedService) {
      setError('Serviço inválido.');
      setShowErrorModal(true);
      return;
    }

    if (credits < selectedService.price) {
      setError('Créditos insuficientes! Insira novos créditos acessando a página Recarga Pix e realize sua consulta.');
      setShowErrorModal(true);
      return;
    }

    if (!placa || !username || !token) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      setShowErrorModal(true);
      return;
    }

    setError('');
    setPdfUrl('');
    setLoading(true);
    setShowImage(false);

    try {
      const response = await axiosInstance.post('/CRV/BuscaDados2', null, {
        params: { placa, username, token, service },
        responseType: 'blob'
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const contentDisposition = response.headers['content-disposition'];
      let fileName = `${placa}_CRLV.pdf`;
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      ReactPixel.track('Purchase', {
        value: selectedService.price,
        currency: 'BRL',
      });

      setCredits((prevCredits) => prevCredits - selectedService.price);
      setPdfUrl(pdfUrl);
      setPdfName(fileName);
      fetchRequestLogs(token);

       // Disparar o evento de consulta bem-sucedida para o GTM
       trackPurchase(service, selectedService.price);

    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      if (error.response) {
        switch (error.response.status) {
          case 303:
            setError('Veículo não encontrado! Verifique se digitou a placa corretamente.');
            break;
          case 401:
            setError('Token inválido ou expirado.');
            break;
          case 400:
            setError('Não foi possível emitir o documento. O veículo pode ter débitos ou restrições que impedem a emissão do documento digital. Acesse o site do Detran para verificar a situação do veículo.');
            break;
            case 403:
            setError('Ocorreu uma instabilidade no órgão emissor responsável pela emissão do documento. Por favor, tente novamente mais tarde.');
            break;
          case 500:
            setError('Confira se escolheu a opção correta no menu "Selecione um Estado" para emissão do CRLV.');
            break;
          case 503:
            setError('Créditos insuficientes! Insira novos créditos acessando a página Recarga Pix e realize sua consulta.');
            break;
          case 504:
            setError('Não foi possível emitir o documento. O veículo pode ter débitos ou restrições que impedem a emissão do documento digital. Acesse o site do Detran para verificar a situação do veículo.');
            break;
          case 600:
            setError('Erro ao consultar: Verifique se os dados da placa foi digitado corretamente e se o Estado Selecionado está correto');
            setShowSupportButton(true);
            break;
          case 601:
          case 602:
            setError('Não foi possível obter os dados (Renavam, CPF/CNPJ) necessários para prosseguir com a consulta. Se o erro persistir, entre em contato com o suporte pelo WhatsApp: (11) 9 9918-4789.');
            break;
          case 603:
            setError('ATPVe não encontrado. Verifique se o veículo já tem comunicação de venda.');
            break;
          case 802:
            setError('CRV Digital não encontrado. Se o erro persistir fale com suporte pelo WhatsApp (11) 9 9918-4789.');
            break;
          case 801:
            setError('2 Via ATPV-e não encontrado. Não existe intenção de venda ou a comunicação de venda já foi realizada e o ATPVE saiu do sistema do Detran.');
            break;
          case 800:
            if (service === 'CrlvRJ') {
              setError('Não foi possível emitir o documento! Verifique a situação do veículo no site do Detran, pois o documento pode não ser digital ou a remissão está bloqueada.');
              setShowSupportButton(true);
            }
            break;
          default:
            setError('Ocorreu uma instabilidade no órgão emissor responsável pela emissão do documento. Por favor, tente novamente mais tarde.');
            break;
        }
      }
      setShowErrorModal(true);
    } finally {
      setLoading(false);
      setService('');
    }
  };

  const handleServiceChange = (e) => {
    const selectedService = e.target.value;
    setService(selectedService);
    setShowModal(false);
    setTermsAccepted(false);
    setIsSpecial(isSpecialState(selectedService));

    if (isSpecialState(selectedService)) {
      setShowModal(true);
    } else {
      const doNotShow = localStorage.getItem('doNotShowInfoModal') !== 'true';
      if (doNotShow) {
        setShowInfoModal(true);
      }
    }
  };

  const handleCloseInfoModal = () => {
    if (doNotShowAgain) {
      localStorage.setItem('doNotShowInfoModal', 'true');
    }
    setShowInfoModal(false);
  };

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
    setShowModal(false);
  };

  const handleDownloadClick = () => {
    if (pdfUrl) {
      // Usuário clicou para baixar o PDF, não exibe o modal de confirmação
    }
  };

  return (
    <MainContainer>
      <GlobalStyle />
      <CustomNavbar />
      <ContentContainer className="sisdok-container d-grid gap-3">
        <FormCard className="shadow p-4">
        <h3>CRLV Digital</h3>
        <p><strong>Aviso Importante:</strong> Novos estados disponiveis para emissão: CE, DF, MS e PI</p>
         <div>
            <Form.Control
              as="select"
              value={service}
              onChange={handleServiceChange}
              className="form-control"
            >
              <option value="">Selecione um Estado</option>
              {services.map((s) => (
                <option key={s.value} value={s.value}>{s.label}</option>
              ))}
            </Form.Control>
            <Form.Control
              type="text"
              placeholder="Placa"
              value={placa}
              onChange={handlePlacaChange}
              className="form-control"
            />
            <Form.Control
              type="text"
              placeholder="Nome de usuário"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
            />
            <Form.Control
              type="text"
              placeholder="Token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              className="form-control"
            />
            <CreditsInfo>Créditos disponíveis: R${credits.toFixed(2)}</CreditsInfo>
            <SubmitButton onClick={handleRequest} special={isSpecial}>
              {isSpecial ? 'Solicitar Documento' : 'Emitir Documento'}
            </SubmitButton>
            {loading && <LoadingImage src="carregamento.gif" alt="Carregando..." />}
          </div>
        </FormCard>
        <PdfCard className="shadow p-4">
          <div>
            {showImage && !pdfUrl && (
              <DocumentImage src="documentocrlv.jpg" alt="Documento CRLV" />
            )}
            {pdfUrl && !loading && (
              <div>
                <PDFViewer pdfUrl={pdfUrl} />
                <PdfDownloadLink href={pdfUrl} download={pdfName} onClick={handleDownloadClick}>Download do PDF</PdfDownloadLink>
              </div>
            )}
          </div>
        </PdfCard>
        <TableCard className="shadow p-4">
          <h3>Histórico Recente</h3>
          <p>Baixe o PDF clicando no link azul. Ficará disponível por 24hrs, depois será excluído automaticamente!</p>
          <RequestLogsTable logs={requestLogs} />
        </TableCard>
      </ContentContainer>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Termos de Emissão de Documento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              A emissão do CRLV Digital para este Estado segue um procedimento diferente, pois ainda não é possível realizá-la de forma automática. Por favor, preencha os dados da placa, nome de usuário e token. Ao clicar no botão, você será direcionado para o WhatsApp da nossa equipe. O atendimento é realizado por ordem de solicitação.
            </p>
            <p>
              Aguarde enquanto fazemos a consulta no site do Detran e informaremos qual documento conseguimos emitir. Se estiver de acordo e for o documento que você precisa, basta efetuar o pagamento. O documento será emitido junto ao Detran no mesmo dia. Após a emissão, enviaremos o arquivo PDF pelo WhatsApp.
            </p>
            <Form.Check
              type="checkbox"
              label="Aceito os termos descritos acima."
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleAcceptTerms} disabled={!termsAccepted}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      )}
      {showInfoModal && (
        <Modal show={showInfoModal} onHide={handleCloseInfoModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Atenção</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Antes de solicitar o documento (CRLV-e), verifique as seguintes considerações:
              Consulte antes no site do detran se o documento já está atualizado no sistema, caso tenha pago os débitos recentemente aguarde até que o detran atualize o documento no sistema.
            </p>
            <p>
              Motivos que fazem o documento não atualizar:
              Comunicado de venda, Intenção de venda, Recall, IPVA em aberto, Bloqueios, Pagamento recente de débitos, óbito.
            </p>
            <p>
              Ao prosseguir com a emissão, o usuário confirma que consultou previamente seu veículo, sendo total responsável caso possua débitos ou restrições que ocasione na emissão de documento desatualizado.
            </p>
            <Form.Check
              type="checkbox"
              label="Não mostrar mensagem novamente"
              checked={doNotShowAgain}
              onChange={(e) => setDoNotShowAgain(e.target.checked)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseInfoModal}>Ok, ciente!</Button>
          </Modal.Footer>
        </Modal>
      )}
      {showErrorModal && (
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Erro</Modal.Title>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </MainContainer>
  );
};

export default DocumentosDigitais;
