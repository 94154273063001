import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { FaFacebook, FaInstagram, FaYoutube, FaSearch, FaCheck } from 'react-icons/fa';
// Global styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
  }
    h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 112%;
    color: #262626;
}
    .col-md-12.text-center {
    background: #262626;
}
 @media (max-width: 768px) {
h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 112%;
    color: #262626;
    padding-top: 20px;
}
}


    @media (max-width: 400px) {
    div {
        width: 380px;
        padding: 11px;
        margin-top: -10px;
    }
        h1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 112%;
    color: #262626;
    padding-top: 20px;
} 
}

@media (min-width: 415px) and (max-width: 430px) {
    div {
        width: 435px;
        padding: 11px;
        margin-top: -10px;
    }
        h1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 112%;
    color: #262626;
    padding-top: 20px;
} 
}

@media (min-width: 400px) and (max-width: 420px) {
    div {
        width: 420px;
        padding: 11px;
        margin-top: -10px;
    }
        h1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 112%;
    color: #262626;
    padding-top: 20px;
} 
}
@media (min-width: 380px) and (max-width: 395px) {
    div {
        width: 395px;
        padding: 11px;
        margin-top: -10px;
    }
        h1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 112%;
    color: #262626;
    padding-top: 20px;
} 
}


        @media (max-width: 360px) {
    div {
        width: 370px;
        padding: 11px;
        margin-top: -10px;
    }

   h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 112%;
    color: #262626;
    padding-top: 20px;
}  
        
}

} 
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;

   @media (max-width: 768px) {
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 100vh;
    background-color: #fff;
    margin-left: -60px;

}
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 1200px;
  margin: 20px;
  flex-wrap: wrap;
  

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextSection = styled.div`
  flex: 1;
  padding: 20px;
  text-align: left;
  
  

  @media (max-width: 768px) {
    text-align: center;
    padding: 0px;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const SmallTitle = styled.h3`
  color: #007bff;
  font-size: 1.2rem;
`;

const LargeTitle = styled.h1`
  
`;

const Paragraph = styled.p`
  font-size: 15px;
  font-weight: 500;

   @media (max-width: 768px) {
  
    font-size: 16px;
    font-weight: 500;
    padding: 15px;

}
`;

const Paragraph1 = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #262626;

  @media (max-width: 768px) {
  
    font-size: 16px;
    font-weight: 500;
    padding: 15px;
    }
`;

const Price = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: green;
  margin: 20px 0;

  @media (max-width: 768px) {
  font-size: 20px;
  font-weight: bold;
  color: green;
  margin-top: -20px;
}
`;

const Button = styled.a`
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 15px 30px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Separator = styled.hr`
  width: 100%;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 40px 0;
  
`;

const Footer = styled.footer`
  width: 100%;
  padding: 0px 0;
  background: #262626;
  
  

  .container {
    display: block;
    justify-content: center;
    align-items: center;
    
}

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
        gap: 30px;
        background: #262626;
        color: white;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 10%;
    margin-bottom: 30px;
    background: #262626;
    color: white;
  }

  h5 {
    margin-bottom: 20px;
    padding-top: 40px;
  }

  ul {
    list-style: none;
    padding: 0;
    
  }

  ul li {
    margin-bottom: 10px;
  }

  ul li a, ul li span {
    color: #bbb;
    text-decoration: none;
    transition: color 0.3s;
    
  }
    .text-muted {
    --bs-text-opacity: 1;
    color: rgb(250 252 253 / 75%) !important;
}

  ul li a:hover {
    color: #fff;
  }

  .social-icons {
    display: flex;
    gap: 10px;
    font-size: 1.5rem;
  }

  @media (max-width: 992px) {
    .col-md-3 {
      width: 45%;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    .col-md-3 {
      width: 100%;
      margin-bottom: 20px;
    }

    .row {
      flex-direction: column;
      align-items: center;
       
       margin-left: 20px;
       margin-top: -50px;
    }
          .HUXwj {
        width: 100%;
        padding: 0px 0;
        background: #fff;
    }
  }
`;

const NewSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  background-color: #fff;
  width: 80%;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    text-align: center;
    align-items: flex-start;
       
  }
`;

const NewTextSection = styled.div`
  flex: 1;
  padding: 20px;

  h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 112%;
    color: #262626;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 18px;
      text-align: left;
    }
      
    .h4, h4 {
    font-size: 18px;
    width: 300px;
    text-align: left;
    }
    
  }
`;

const NewImageSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const InfoList = styled.div`
  margin: 20px 0;

  ul {
    list-style: none;
    padding: 0;
    font-size: 18px;

    li {
      margin: 10px 0;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        color: #007bff;
      }
    }
  }
`;

const Landing = () => {
  return (
    <Container>
      <GlobalStyle />
      <Content>
        <TextSection>
          
          <LargeTitle>Baixe o Licenciamento Digital (CRLV-e) do seu veículo pela Placa</LargeTitle>
          <Paragraph>Emissão para os estados: AC, AP, GO, MA, MG, MT, PA, PR, RN, RR, SP, TO</Paragraph>
          <Price><strong>Custo: </strong>Apenas R$25 por CRLV Digital!</Price>
          <Button href="https://crlvnahora.com/cadastro">Cadastre-se Agora</Button>
          <Paragraph1>  
          </Paragraph1>
          <SmallTitle>Nota Importante:</SmallTitle>
          <Paragraph1> O CRLV pode ser <strong>emitido gratuitamente</strong> através dos canais oficiais do DETRAN.
          Nosso serviço é uma alternativa conveniente para quem busca agilidade e suporte adicional.
          </Paragraph1>
        </TextSection>
        <ImageSection>
          <Image src="/licenciamento-digital-crlv-e-pela-placa.png" alt="Veículo com documento CRLV" />
        </ImageSection>
      </Content>

       {/* Nova seção */}
       <NewSectionContainer>
        <NewImageSection>
          <Image src="/mulher.png" alt="Imagem de uma mulher" />
        </NewImageSection>
        <NewTextSection>
        <h1>CRLV Na Hora</h1>
          <p>O sistema ideal para emissão de documentos e consultas veiculares.</p>

          <InfoList>
            <ul>
              <h4><strong>Consultas Veiculares com preços a partir de R$10!</strong></h4>
              <li></li>
              <li><FaSearch /> Base Estadual</li>
              <li><FaSearch /> Base Nacional</li>
              <li><FaSearch /> Consulta Premium</li>
              <li><FaSearch /> Gravame</li>
              <li><FaSearch /> Leilão Simples</li>
              <li><FaSearch /> Leilão Premium</li>
              <li><FaSearch /> Renainf</li>
              <li><FaSearch /> Sinistro</li>
            </ul>

            <ul>
              <h4><strong>Por que escolher o CRLV Na Hora?</strong></h4>
              <li></li>
              <li><FaCheck /> Sem mensalidades</li>
              <li><FaCheck /> 24 horas por dia</li>
              <li><FaCheck /> Reembolso garantido</li>
            </ul>
          </InfoList>
          
          <Button href="https://crlvnahora.com/cadastro">Clique Aqui e Faça seu Cadastro</Button>
        </NewTextSection>
        
      </NewSectionContainer>
      

      <Separator />
      <Footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h5>Políticas</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="https://crlvnahora.com/lgpd" className="nav-link p-0 text-muted">Políticas de Privacidade</a></li>
                <li className="nav-item mb-2"><a href="https://crlvnahora.com/termosUso" className="nav-link p-0 text-muted">Termos de Uso</a></li>
              </ul>
            </div>
            <div className="col-md-3">
            <h5>Links para DETRANs Oficiais</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="https://www.detran.ac.gov.br/" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran AC
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.detran.ap.gov.br/detranap/" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran AP
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://goias.gov.br/detran/" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran GO
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.detran.ma.gov.br/inicio/paginas/Home.xhtml" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran MA
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://transito.mg.gov.br/veiculos" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran MG
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.detran.mt.gov.br/" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran MT
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.detran.pa.gov.br/index_.php" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran PA
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.detran.pr.gov.br/" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran PR
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.detran.rj.gov.br/" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran RJ
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="http://www.detran.rn.gov.br/" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran RN
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.detran.rr.gov.br/" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran RR
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.detran.sp.gov.br/wps/portal/portaldetran/cidadao/home" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran SP
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.to.gov.br/detran" className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe"></i> Detran TO
                </a>
              </li>
            </ul>
          </div>
            <div className="col-md-3">
              <h5>Contatos</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><span className="nav-link p-0 text-muted">Email: suporte@crlvnahora.com.br</span></li>
                <li className="nav-item mb-2"><span className="nav-link p-0 text-muted">Telefone: (11) 9 9918-4789</span></li>
              </ul>
            </div>
            <div className="col-md-3">
              <h5>Redes Sociais</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="https://www.facebook.com/CRLVnaHora" className="nav-link p-0 text-muted"><FaFacebook /> Facebook</a></li>
                <li className="nav-item mb-2"><a href="https://www.instagram.com/crlvnahora/" className="nav-link p-0 text-muted"><FaInstagram /> Instagram</a></li>
                <li className="nav-item mb-2"><a href="https://www.youtube.com/@crlvnahora" className="nav-link p-0 text-muted"><FaYoutube /> YouTube</a></li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <p>© 2024 CRLV Na Hora - Todos os direitos reservados.</p>
            </div>
          </div>
        </div>
      </Footer>
    </Container>
  );
};

export default Landing;
