import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Card, Table, Button, Modal } from 'react-bootstrap';
import jsPDF from 'jspdf';
import styled, { createGlobalStyle } from 'styled-components';
import CustomNavbar from './Navbar';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
  }
`;

const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
`;

const ConsultaCard = styled(Card)`
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  padding: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #007bff;
  border-radius: 4px;
`;

const CreditsInfo = styled.p`
  color: #007bff;
  font-size: large;
`;

const ConsultaButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
`;

const ContainerConsulta = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
   @media (max-width: 767px) {
   width: 100%;
   }
`;

const ConsultaGravame = () => {
  const [placa, setPlaca] = useState('');
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [consultaData, setConsultaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const hcaptchaRef = useRef(null);

  // Carregar valores do localStorage ao inicializar o componente
    useEffect(() => {
      const storedToken = localStorage.getItem('token');
      const storedUsername = localStorage.getItem('username');
      if (storedToken) {
        setToken(storedToken);
      }
      if (storedUsername) {
        setUsername(storedUsername);
      }
    }, []);
  
    // Salvar valores no localStorage quando mudarem
    useEffect(() => {
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
    }, [token, username]);

  const fetchCredits = async () => {
    try {
      const response = await axiosInstance.get('/api/user/credits', { params: { token } });
      setCredits(response.data.credits);
    } catch (err) {
      console.error('Erro ao obter créditos:', err);
      setError('Erro ao obter créditos do usuário.');
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (token) fetchCredits();
  }, [token]);

  const handlePlacaChange = (e) => {
    setPlaca(e.target.value.replace(/\s/g, '').toUpperCase());
  };

  const handleConsulta = async () => {
    if (!placa || !token || !captchaToken || !username) {
      setError('Preencha todos os campos e complete o CAPTCHA.');
      setShowErrorModal(true);
      return;
    }

    if (credits < 10) {
      setError('Créditos insuficientes.');
      setShowErrorModal(true);
      return;
    }

    setLoading(true);
    setConsultaData(null);

    try {
      const response = await axiosInstance.post('/CRV/Gravame', null, {
        params: { placa, username, token },
      });
      setConsultaData(response.data);
      setCredits((prev) => prev - 10);
    } catch (err) {
      console.error('Erro na consulta:', err);
      setError('Erro ao realizar a consulta. Tente novamente.');
      setShowErrorModal(true);
    } finally {
      setLoading(false);
      if (hcaptchaRef.current) {
        hcaptchaRef.current.resetCaptcha();
        setCaptchaToken(null);
      }
    }
  };

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();

    const loadLogo = async () => {
      const response = await fetch('/favicon2-512x512.png');
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = () => reject('Erro ao carregar a imagem');
        reader.readAsDataURL(blob);
      });
    };

    try {
      const logoBase64 = await loadLogo();
      doc.addImage(logoBase64, 'PNG', 10, 10, 20, 20);

      doc.setFontSize(30);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(40, 40, 120);
      doc.text('Consulta Gravame', 60, 20);

      doc.setLineWidth(0.5);
      doc.setDrawColor(40, 40, 120);
      doc.line(10, 35, 200, 35);

      const addSectionTitle = (title, yPosition) => {
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(40, 40, 120);
        doc.text(title, 10, yPosition);
        doc.line(10, yPosition + 2, 200, yPosition + 2);
      };

      const addDataRow = (field, value, x, y, width) => {
        doc.setFontSize(10);
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(0, 0, 0);

        doc.setDrawColor(200, 200, 200);
        doc.rect(x, y, width, 10);

        doc.text(field, x + 2, y + 6);
        doc.text(value || '-', x + width / 2 + 2, y + 6);
      };

      let yPos = 45;

      addSectionTitle('Dados do Veículo', yPos);
      yPos += 5;
      const vehicleData = Object.entries(consultaData.dadosVeiculo || {}).map(([key, value]) => [
        key.replace(/([A-Z])/g, ' $1').toUpperCase(),
        value,
      ]);
      vehicleData.forEach(([field, value]) => {
        addDataRow(field, value, 10, yPos, 190);
        yPos += 12;
      });

      addSectionTitle('Dados do Agente', yPos + 10);
      yPos += 15;
      const agentData = Object.entries(consultaData.dadosAgente || {}).map(([key, value]) => [
        key.replace(/([A-Z])/g, ' $1').toUpperCase(),
        value,
      ]);
      agentData.forEach(([field, value]) => {
        addDataRow(field, value, 10, yPos, 190);
        yPos += 12;
      });

      addSectionTitle('Dados da Restrição', yPos + 10);
      yPos += 15;
      const restrictionData = Object.entries(consultaData.dadosRestricao || {}).map(([key, value]) => [
        key.replace(/([A-Z])/g, ' $1').toUpperCase(),
        value,
      ]);
      restrictionData.forEach(([field, value]) => {
        addDataRow(field, value, 10, yPos, 190);
        yPos += 12;
      });

      doc.setFontSize(10);
      doc.setFont('helvetica', 'italic');
      doc.setTextColor(100, 100, 100);
      doc.text(
        'Relatório gerado automaticamente pelo sistema.',
        10,
        280
      );
      doc.text(
        `Data: ${new Date().toLocaleDateString()} - Hora: ${new Date().toLocaleTimeString()}`,
        150,
        280
      );

      doc.save(`ConsultaGravame_${consultaData.dadosVeiculo?.PLACA || 'SEM_PLACA'}.pdf`);
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
    }
  };

  return (
    <MainContainer>
      <GlobalStyle />
      <CustomNavbar />
      <ConsultaCard>
        <Card.Body>
          <h1>Gravame (SNG) - R$10</h1>
          <Input type="text" placeholder="Digite a placa" value={placa} onChange={handlePlacaChange} />
          <Input type="text" placeholder="Digite o username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <Input type="text" placeholder="Digite o token" value={token} onChange={(e) => setToken(e.target.value)} />
          <CreditsInfo>Créditos disponíveis: R${credits.toFixed(2)}</CreditsInfo>
          <HCaptcha sitekey="71549bc6-209d-4506-9f97-ee716882c326" onVerify={setCaptchaToken} ref={hcaptchaRef} />
          <ConsultaButton onClick={handleConsulta} disabled={loading || !captchaToken || placa.length !== 7}>
            {loading ? 'Consultando...' : 'Consultar'}
          </ConsultaButton>
        </Card.Body>
      </ConsultaCard>
      {consultaData && (
        <ContainerConsulta>
          <Button variant="primary" onClick={handleDownloadPDF}>
            Download PDF
          </Button>
        </ContainerConsulta>
      )}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Erro</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </MainContainer>
  );
};

export default ConsultaGravame;
